import Button from "components/base/Button/Button";
import useGetData from "hooks/api/useGetData";
import PropTypes from "prop-types";
import { Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import styles from "./styles.module.scss";

interface DialogProps {
  message: string;
  onConfirm: () => void;
  onCancel: () => void;
  children: JSX.Element[] | JSX.Element;
  isLoading?: boolean;
}

const Dialog = ({ message, onConfirm, onCancel, children, isLoading }: DialogProps) => {
  const { t } = useTranslation();
  const { themes } = useGetData();
  return (
    <div className={styles.dialogCard} data-testid="dialog">
      <h3 className="text-center">{message}</h3>
      <div className={styles.bodyWrapper}>{children}</div>
      <div className="mt-4">
        <Row>
          <Col xs="6">
            <Button
              variant="secondary"
              title={t("secondScreen.cancel")}
              onClick={onCancel}
              disabled={isLoading}
            />
          </Col>
          <Col xs="6">
            <Button
              variant="primary"
              title={t("secondScreen.yes")}
              onClick={onConfirm}
              isLoading={isLoading}
              style={{ backgroundColor: themes.primaryColor }}
            />
          </Col>
        </Row>
      </div>
    </div>
  );
};

Dialog.propTypes = {
  message: PropTypes.string.isRequired,
  onConfirm: PropTypes.func,
  onCancel: PropTypes.func,
  children: PropTypes.element
};

export default Dialog;
