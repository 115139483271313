import { useTranslation } from "react-i18next";
import styles from "./styles.module.scss";

interface InstructionProps {
  imgUrl: string;
  // eslint-disable-next-line
  htmlContent: any;
}

const Instruction = ({ imgUrl, htmlContent }: InstructionProps) => {
  const { t } = useTranslation();

  return (
    <div>
      <div className="m-divider-dashed-order mb-3"></div>
      <div className="text-center">
        <p>{t("secondScreen.confirmationDescription")}</p>
      </div>
      <div className={styles.paymentInstructionBox} data-testid="instruction">
        <div className={styles.paymentMethodItemCard}>
          <div
            data-testid="instruction-image"
            className={styles.paymentMethodItemImage}
            style={{ backgroundImage: `url(${imgUrl})` }}
          ></div>
        </div>
        <span
          data-testid="instruction-text"
          className={styles.paymentInstructionText}
          dangerouslySetInnerHTML={{ __html: htmlContent }}
        ></span>
      </div>
    </div>
  );
};

export default Instruction;
