import { useMutatePay } from "api/mutations";
import { useQueryPrl } from "api/queries";
import TableOrder from "components/base/TableOrder";
import PrlOrder from "components/ui/YourOrder/PrlOrder";
import ProductOrder from "components/ui/YourOrder/ProductOrder";
import { CUSTOMER_FLOW_TYPE, PRODUCT_FLOW_TYPE } from "constants/flowTypes";
import useGetData from "hooks/api/useGetData";
import useGetQuote from "hooks/api/useGetQuote";
import useGetStatus from "hooks/api/useGetStatus";
import useUser from "hooks/useUser";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { EnumPaymentStatus, OrderStatusObject } from "types/api/api";
import formatCurrency from "utils/formatCurrency";
import CircleCheckmarkIcon from "../../../assets/icons/CircleCheckmarkIcon";
import CircleErrormarkIcon from "../../../assets/icons/CircleErrormarkIcon";
import styles from "./styles.module.scss";

interface ResultPageProps {
  data: OrderStatusObject;
  isLoading: boolean;
}

const ResultPage = ({ data, isLoading }: ResultPageProps) => {
  const { id = "", orderId } = useParams();
  const { themes } = useGetData();
  const { t, i18n } = useTranslation();
  const { user } = useUser();
  /* eslint-disable */
  const { isError: isErrorPay, error: errorPay }: { isError: boolean; error: any } = useMutatePay(
    id as string
  );

  const { isError: isErrorQuote, error: errorQuote } = useGetQuote();
  const { isError: isErrorStatus, error: errorStatus } = useGetStatus({});
  const { data: prlData, isLoading: isLoadingPrl } = useQueryPrl(id);

  const renderPaymentErrorMessage = () =>
    data.error?.description?.find((l) => l.lang === i18n.language)?.text;

  const renderStatusMessage = () => {
    if (isErrorQuote && errorQuote?.data?.errorMsg) {
      return errorQuote?.data?.errorMsg;
    }

    if (isErrorStatus && errorStatus?.data?.errorMsg) {
      return errorStatus?.data?.errorMsg;
    }

    if (isErrorPay && errorPay?.data?.errorMsg) {
      return errorPay?.data?.errorMsg;
    }

    return "";
  };

  const SuccessScreen = () => {
    return (
      <div>
        <CircleCheckmarkIcon className={styles.checkmarkIcon} color={themes.primaryColor} />
        <div className="m-h2" data-testid="heading-first">
          {t("successScreen.paymentSuccessful")}
        </div>
        <div className="m-h4 mt-3" data-testid="heading-first">
          {t("successScreen.thankYou")}
        </div>
      </div>
    );
  };

  const ErrorScreen = () => {
    return (
      <div>
        <CircleErrormarkIcon />
        <div className="m-h2-error" data-testid="heading-first-error">
          {t("errorScreen.heading1")}
        </div>
        <div className={styles.errorMessage} data-testid="error-message">
          {data?.status === EnumPaymentStatus.ERRORED ? renderPaymentErrorMessage() : renderStatusMessage()}
        </div>
      </div>
    );
  };
  /* eslint-disable */

  const content = {
    rows: [],
    headings: []
  };

  const orderDetails = localStorage.getItem(`${orderId}`);

  const details = JSON.parse(`${orderDetails}`);

  const footerRows = [
    {
      title: t("yourOrder.subTotal"),
      value: formatCurrency(details?.amount || data?.amount || Number(user?.amount))
    },
    {
      title: t("yourOrder.fees"),
      value: formatCurrency(details?.quote || data?.quote || Number(user?.quote)),
      highlight: true
    },
    {
      title: t("yourOrder.total"),
      value: formatCurrency(
        data?.total || Number(user?.amount) + Number(user?.quote) || details?.amount + details?.quote
      )
    }
  ];

  return (
    <div>
      {data?.status === EnumPaymentStatus.SUCCESS && <SuccessScreen />}
      {data?.status === EnumPaymentStatus.ERRORED && <ErrorScreen />}
      <div className={styles.oderWithFees}>
        {/* Here, your order is taking a quote information from statusQuery as user already started a payment process */}
        {String(prlData?.prlFlowType) === PRODUCT_FLOW_TYPE ? (
          <ProductOrder />
        ) : String(prlData?.prlFlowType) === CUSTOMER_FLOW_TYPE ? (
          <PrlOrder summary isLoading={isLoadingPrl} />
        ) : (
          <TableOrder isLoading={isLoading} content={content} footerRows={footerRows} />
        )}
      </div>
    </div>
  );
};

export default ResultPage;
