import { PRL_FLOW_TYPE } from "constants/flowTypes";
import { useParams } from "react-router-dom";
import useGetData from "hooks/api/useGetData";
import useQueryStatus from "api/queries/useQueryStatus";
import { EnumPaymentStatus } from "types/api/api";

const useGetStatus = ({
  refetchInterval = 0,
  isFinalStatus = false
}: { refetchInterval?: number; isFinalStatus?: boolean } = {}) => {
  const { prlData } = useGetData();
  const { type: flowType } = useParams();
  const { isFetching, isLoading, refetch, data, error, isError } = useQueryStatus(String(prlData?.orderId), {
    enabled: !!(flowType === PRL_FLOW_TYPE && prlData?.orderId) && !isFinalStatus,
    refetchInterval: refetchInterval
  });

  const isDone =
    !isFetching &&
    (data?.status === EnumPaymentStatus.SUCCESS ||
      data?.status === EnumPaymentStatus.ERROREDREFUNDED ||
      data?.status === EnumPaymentStatus.ERRORED);

  const isPending = data?.status === EnumPaymentStatus.PENDING;
  const isOpen = data?.status === EnumPaymentStatus.OPEN;

  // eslint-disable-next-line
  return { data, isError, isFetching, isLoading, refetch, error: error as any, isDone, isPending, isOpen };
};

export default useGetStatus;
