import { PRIMARY_COLOR } from "constants/layoutColors";
import * as React from "react";

function CircleCheckmarkIcon(props) {
  const { color = PRIMARY_COLOR } = props;
  return (
    <svg width="1em" height="1em" viewBox="0 0 27 27" fill="none" {...props}>
      <circle cx={13.5} cy={13.5} r={13.5} fill={color} />
      <path
        d="M18.837 9.418l-6.907 6.907-3.14-3.14"
        stroke="#fff"
        strokeWidth={3.14}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

const MemoCircleCheckmarkIcon = React.memo(CircleCheckmarkIcon);
export default MemoCircleCheckmarkIcon;
