import { COMPANY_FLOW_TYPE, DRAWER_FLOW_TYPE, PRL_FLOW_TYPE, PRODUCT_FLOW_TYPE } from "constants/flowTypes";
import useGetData from "hooks/api/useGetData";
import { useParams } from "react-router-dom";
import useQueryQuote from "api/queries/useQueryQuote";
import useGetStatus from "hooks/api/useGetStatus";
import { QuoteRequestObject } from "types/api/api";
import { isNumber, isString } from "lodash";
import extractIdFromUrl from "utils/extractIdFromUrl";
import { useAppContext } from "context/AppContext";

const useGetQuote = () => {
  const { prlData, productData } = useGetData();
  const { id, type: flowType } = useParams();
  const { data: dataStatus } = useGetStatus();

  const { paymentMethod, productQuantity, amount, companyPrlUrl, isProduct } = useAppContext();
  const prlUuid = extractIdFromUrl(companyPrlUrl);
  const quoteBodyData: QuoteRequestObject = {
    paymentProviderId: paymentMethod,
    amount:
      flowType === DRAWER_FLOW_TYPE
        ? Number(amount) * productQuantity
        : flowType === COMPANY_FLOW_TYPE
        ? Number(amount) * productQuantity
        : flowType === PRL_FLOW_TYPE && isProduct
        ? Number(amount) * productQuantity
        : (prlData?.amount?.value || productData?.amount?.value || 1) * productQuantity,
    prlUuid: flowType === COMPANY_FLOW_TYPE || flowType === DRAWER_FLOW_TYPE ? prlUuid : id,
    productUuid: id
  };

  if (flowType === PRODUCT_FLOW_TYPE) {
    delete quoteBodyData.prlUuid;
  }

  if (flowType === PRL_FLOW_TYPE) {
    if (isProduct) {
      delete quoteBodyData.prlUuid;
    } else {
      delete quoteBodyData.productUuid;
    }
  }

  if (flowType === COMPANY_FLOW_TYPE || flowType === DRAWER_FLOW_TYPE) {
    delete quoteBodyData.productUuid;
  }

  const isQuoteBodyDataValid = Object.values(quoteBodyData).every((value) => {
    if (isString(value)) {
      return value.length > 0;
    }
    if (isNumber(value)) {
      return value > 0;
    }

    return true;
  });

  const {
    data: dataQuote,
    error: errorQuote,
    isError: isErrorQuote,
    isLoading: isLoadingQuote,
    isSuccess: isSuccessQuote
  } = useQueryQuote(quoteBodyData, { enabled: isQuoteBodyDataValid });

  let quote;

  quote = dataQuote?.quote || dataStatus?.quote;
  quote = quote ? Number(quote) : 0;

  return {
    quote,
    data: dataQuote,
    // eslint-disable-next-line
    error: errorQuote as any,
    isError: isErrorQuote,
    isLoading: isLoadingQuote,
    isSuccess: isSuccessQuote
  };
};

export default useGetQuote;
